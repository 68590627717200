

















































import { Prop, Component, Vue } from 'vue-property-decorator'
import EditIconNoSquare from '@/app/ui/assets/edit_icon_nosquare.vue'
import TrashIcon from '@/app/ui/assets/trash_icon_alt.vue'
import { CategorySectionProduct } from '@/domain/entities/CategorySection'

@Component({
  components: {
    EditIconNoSquare,
    TrashIcon,
  },
})
export default class CategoryItem extends Vue {
  @Prop({ required: true }) private categoryId!: number
  @Prop({ required: true }) private title!: string
  @Prop({ required: true }) private banners!: string[]
  @Prop({ required: true }) private products!: CategorySectionProduct[]
  @Prop({ default: false }) private draggable!: boolean

  get bannerTitles(): string[] {
    return ['Homepage Category Banner', 'Detail Category Banner']
  }

  get productList(): string {
    return this.products?.map((product) => product.productName).join(', ')
  }
}
