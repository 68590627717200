







































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import CategoryItem from './components/CategoryItem/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import ModalSuccess from './components/ModalSuccess/index.vue'
import Draggable from 'vuedraggable'
import controller from '@/app/ui/controllers/CategorySectionController'
import { CategorySection } from '@/domain/entities/CategorySection'
import { EventBusConstants } from '@/app/infrastructures/misc'

@Component({
  components: {
    Button,
    CategoryItem,
    Draggable,
    Modal,
    ModalSuccess,
    LoadingOverlay,
  },
})
export default class CategorySectionPage extends Vue {
  reposition = false
  modalDelete: number | null = null
  modalDeleteTitle = ''
  backupCategorySection: CategorySection[] = []
  successDeleteModal = false
  controller = controller

  created(): void {
    controller.getCategorySectionList()
  }

  @Watch('controller.statusRepositionCategorySection')
  onStatusRepositionCategorySectionChanged(status: string): void {
    if (status !== '' && status === EventBusConstants.REPOSITION_CATEGORY_SECTION_SUCCESS) {
      this.$notify({
        title: 'Reposition Category Section',
        text: 'Reposition Category Section Success',
        type: 'success',
        duration: 5000,
      })

      this.reposition = false
      this.backupCategorySection = []
    }
    controller.setStatusRepositionCategorySection('')
  }

  @Watch('controller.statusDeleteCategorySection')
  onStatusDeleteCategorySectionChanged(status: string): void {
    if (status !== '' && status === EventBusConstants.DELETE_CATEGORY_SECTION_SUCCESS) {
      this.modalDelete = null
      this.modalDeleteTitle = ''
      this.successDeleteModal = true
    }
    controller.setStatusDeleteCategorySection('')
  }

  @Watch('isLoading')
  loadingWatcher(value: boolean): void {
    if (!value && this.categorySectionData.length === 0) {
      this.$router.push({ name: 'HomePageCategorySectionCreate' })
    }
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get categorySectionData(): CategorySection[] {
    return controller.categorySectionData
  }

  set categorySectionData(categorySection: CategorySection[]) {
    controller.setCategorySectionData(categorySection)
  }

  private openReposition(): void {
    this.reposition = true
    this.backupCategorySection = <CategorySection[]>JSON.parse(
      JSON.stringify(this.categorySectionData)
    )
  }

  private cancelReposition(): void {
    this.reposition = false
    this.categorySectionData = this.backupCategorySection
    this.backupCategorySection = []
  }

  private deleteConfirmation(categoryId: number, title: string): void {
    this.modalDelete = categoryId
    this.modalDeleteTitle = title
  }

  private deleteCategorySection(): void {
    if (this.modalDelete !== null) {
      controller.deleteCategorySection(this.modalDelete)
    }
  }

  private saveReposition(): void {
    controller.repositionCategorySection(
      this.categorySectionData.map(category => <number>category.id)
    )
  }

  beforeDestroy(): void {
    controller.setStatusRepositionCategorySection('')
    controller.setStatusDeleteCategorySection('')
  }
}
